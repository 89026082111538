import {Helmet} from 'react-helmet';
import Header from '../header/Header';
import Main from '../main/Main';
import AboutProject from '../aboutproject/AboutProject';
import Gallery from '../gallery/Gallery';
import HelpUs from '../helpUs/HelpUs';
import Column from '../column/Column';
import Contacts from '../contacts/Contacts';
import Footer from '../footer/Footer';
import VideoPlayer from '../video/Video';

const MainPage = ()=>{
    return(
        <>
        <Helmet>
            <html lang='ru'/>
            <meta
                name="description"
                content="Main page"
            />
            <title>Мотопробег Дорога Жизни</title>
        </Helmet>
            <Header/>
            <Main/>
            <AboutProject/>
            <VideoPlayer/>
            <Gallery/>
            <HelpUs/>
            <Column/>
            <Contacts/>
            <Footer/>
        </>
    )
}
export default MainPage;