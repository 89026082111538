import ReactPlayer from 'react-player';
import './video.css';

const videoLink = 'https://youtu.be/JIgRBc860eQ';

const VideoPlayer = ()=>{
    return(
        <div className="video_wrapp">
            <ReactPlayer
                className='react-player'
                url={videoLink}
                width='100%'
                height='100%'
                controls
            />
        </div>
    )
}
export default VideoPlayer;