import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import './biennale.css'

const Biennale = ()=>{

    const imgData = ['/images/img/min_1.jpg', '/images/img/min_2.jpg', '/images/img/min_3.jpg','/images/img/min_4.jpg','/images/img/min_5.jpg','/images/img/min_6.jpg','/images/img/min_7.jpg','/images/img/min_8.jpg','/images/img/min_9.jpg',
    '/images/img/min_10.jpg','/images/img/min_11.jpg','/images/img/min_12.jpg','/images/img/min_13.jpg','/images/img/min_14.jpg','/images/img/min_15.jpg','/images/img/min_16.jpg','/images/img/min_17.jpg','/images/img/min_18.jpg','/images/img/min_19.jpg',
    '/images/img/min_20.jpg','/images/img/min_21.jpg','/images/img/min_22.jpg','/images/img/min_23.jpg','/images/img/min_24.jpg','/images/img/min_25.jpg','/images/img/min_26.jpg','/images/img/min_27.jpg','/images/img/min_28.jpg','/images/img/min_29.jpg',
    '/images/img/min_30.jpg','/images/img/min_31.jpg','/images/img/min_32.jpg','/images/img/min_33.jpg','/images/img/min_34.jpg','/images/img/min_35.jpg','/images/img/min_36.jpg','/images/img/min_37.jpg','/images/img/min_38.jpg','/images/img/min_39.jpg',
    '/images/img/min_40.jpg','/images/img/min_41.jpg','/images/img/min_42.jpg','/images/img/min_43.jpg','/images/img/min_44.jpg','/images/img/min_45.jpg','/images/img/min_46.jpg','/images/img/min_47.jpg','/images/img/min_48.jpg','/images/img/min_49.jpg',
    '/images/img/min_50.jpg','/images/img/min_51.jpg','/images/img/min_52.jpg','/images/img/min_53.jpg','/images/img/min_54.jpg','/images/img/min_55.jpg','/images/img/min_56.jpg','/images/img/min_57.jpg','/images/img/min_58.jpg','/images/img/min_59.jpg',
    '/images/img/min_60.jpg','/images/img/min_61.jpg','/images/img/min_62.jpg','/images/img/min_63.jpg','/images/img/min_64.jpg','/images/img/min_65.jpg','/images/img/min_66.jpg','/images/img/min_67.jpg','/images/img/min_68.jpg','/images/img/min_69.jpg',
    '/images/img/min_70.jpg','/images/img/min_71.jpg','/images/img/min_72.jpg','/images/img/min_73.jpg','/images/img/min_74.jpg','/images/img/min_75.jpg','/images/img/min_76.jpg','/images/img/min_77.jpg','/images/img/min_78.jpg','/images/img/min_79.jpg',
    '/images/img/min_80.jpg','/images/img/min_81.jpg','/images/img/min_82.jpg','/images/img/min_83.jpg','/images/img/min_84.jpg','/images/img/min_85.jpg','/images/img/min_86.jpg','/images/img/min_87.jpg','/images/img/min_88.jpg','/images/img/min_89.jpg',
    '/images/img/min_90.jpg','/images/img/min_91.jpg','/images/img/min_92.jpg','/images/img/min_93.jpg','/images/img/min_94.jpg','/images/img/min_95.jpg','/images/img/min_96.jpg','/images/img/min_97.jpg','/images/img/min_98.jpg','/images/img/min_99.jpg',
    '/images/img/min_100.jpg','/images/img/min_101.jpg','/images/img/min_102.jpg','/images/img/min_103.jpg','/images/img/min_104.jpg','/images/img/min_105.jpg','/images/img/min_106.jpg','/images/img/min_107.jpg','/images/img/min_108.jpg','/images/img/min_109.jpg',
    '/images/img/min_110.jpg','/images/img/min_111.jpg','/images/img/min_112.jpg','/images/img/min_113.jpg','/images/img/min_114.jpg','/images/img/min_115.jpg','/images/img/min_116.jpg','/images/img/min_117.jpg','/images/img/min_118.jpg','/images/img/min_119.jpg',
    '/images/img/min_120.jpg','/images/img/min_121.jpg','/images/img/min_122.jpg','/images/img/min_123.jpg','/images/img/min_124.jpg','/images/img/min_125.jpg','/images/img/min_126.jpg','/images/img/min_127.jpg','/images/img/min_128.jpg','/images/img/min_129.jpg',
    '/images/img/min_130.jpg','/images/img/min_131.jpg','/images/img/min_132.jpg','/images/img/min_133.jpg','/images/img/min_134.jpg','/images/img/min_135.jpg','/images/img/min_136.jpg','/images/img/min_137.jpg','/images/img/min_138.jpg','/images/img/min_139.jpg',
    '/images/img/min_140.jpg','/images/img/min_141.jpg','/images/img/min_142.jpg','/images/img/min_143.jpg','/images/img/min_144.jpg','/images/img/min_145.jpg','/images/img/min_146.jpg','/images/img/min_147.jpg','/images/img/min_148.jpg','/images/img/min_149.jpg',
    '/images/img/min_150.jpg','/images/img/min_151.jpg','/images/img/min_152.jpg','/images/img/min_153.jpg','/images/img/min_154.jpg','/images/img/min_155.jpg','/images/img/min_156.jpg','/images/img/min_157.jpg','/images/img/min_158.jpg','/images/img/min_159.jpg',
    '/images/img/min_160.jpg','/images/img/min_161.jpg','/images/img/min_162.jpg','/images/img/min_163.jpg','/images/img/min_164.jpg','/images/img/min_165.jpg','/images/img/min_166.jpg','/images/img/min_167.jpg','/images/img/min_168.jpg','/images/img/min_169.jpg',
    '/images/img/min_170.jpg','/images/img/min_171.jpg','/images/img/min_172.jpg','/images/img/min_173.jpg','/images/img/min_174.jpg','/images/img/min_175.jpg','/images/img/min_176.jpg','/images/img/min_177.jpg','/images/img/min_178.jpg','/images/img/min_179.jpg',
    '/images/img/min_180.jpg','/images/img/min_181.jpg','/images/img/min_182.jpg','/images/img/min_183.jpg','/images/img/min_184.jpg','/images/img/min_185.jpg','/images/img/min_186.jpg','/images/img/min_187.jpg','/images/img/min_188.jpg','/images/img/min_189.jpg',
    '/images/img/min_190.jpg','/images/img/min_191.jpg','/images/img/min_192.jpg','/images/img/min_193.jpg','/images/img/min_194.jpg','/images/img/min_195.jpg','/images/img/min_196.jpg','/images/img/min_197.jpg','/images/img/min_198.jpg','/images/img/min_199.jpg',
    '/images/img/min_200.jpg'];


return(
        <motion.div className="biennale"
            initial={{
                x:0,
                opacity:0,
            }}
            animate={{
                x:0,
                opacity:1,
            }}
            transition={{
                delay: 0.8,
            }}
        >
            <Helmet>
                <meta
                    name="description"
                    content="motorcycle foto"
                />
                <title>Мотопробег Фотогалерея</title>
            </Helmet>
            <h1 className='biennale_title' >Ваши лица...</h1>
            <div className="biennale_image"  >
                {imgData.map((item,index)=>{
                    return(
                        <img src={item} alt="bikers-foto" className="biennale_foto" key={index}/>
                    )
                })}
            </div>
            <Link to = '/' className="biennale_close"><button className='biennake_btn'>на главную</button></Link>
        </motion.div>
    )
}
export default Biennale;