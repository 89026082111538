import './headerInfo.css';

const HeaderInfo = function(){
    return(
        <div className="header_info">
            <h1 className="header_info-title">Мотопробег</h1>
            <img src="/images/war.png" alt="img" className="header_info-img" />
            <h2 className="header_info-title info_title">Дорога жизни</h2>
        </div>
    )
}
export default HeaderInfo;