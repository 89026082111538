import './headerLogo.css';
import {Link} from 'react-router-dom';

const HeaderLogo = function(){
    return(
        <div className='header_logo'>
            <Link  to="/" className="header_logo-link">
                <img src='/images/logo.png' alt='logo' className='header_logo-img'/>
            </Link>
        </div>
    )
}
export default HeaderLogo;