import React,{useState} from 'react';
import HeaderLogo from '../headerLogo/Headerlogo';
import HeaderInfo from '../headerInfo/HeaderInfo';
import HeaderBurger from '../headerBurger/HeaderBurger';
import Navigation from '../navigation/Navigation';
import '../header/header.css';

const Header = function(){
    const[isActive,setActive] = useState('');
    const handleToggle = ()=>{
        setActive(!isActive)
    }
    return(
        <div className="header">
            <div className="header_top">
                <HeaderLogo/>
                <HeaderInfo/>
                <HeaderBurger handleToggle = {handleToggle} isActive = {isActive}/>
            </div>
                <Navigation isActive = {isActive} />
        </div>
    )
}
export default Header;