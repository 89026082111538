import React from 'react';
import { YMaps, Map,Placemark } from '@pbe/react-yandex-maps';
import './column.css';

const Column = ()=>{
    return(
        <div className="column">
            <h3 className='column_title'>Колонна</h3>
            <div className="column_wrapper">
                <div className="column_description">
                    <p className='text_part'><span className="big_letter">У</span>же более десяти лет , колонна собирается на  шестом километре дороги жизни. На АЗС Газпромнефть.
                        <span className="big_letter">н</span>а всём пути по Дороге Жизни, заправок больше нет. <span className="big_letter">П</span>оэтому перед тем как занять место в колонне, заправляемся из расчёта на <span className='text_num'>150-200</span> км.
                        <span className="big_letter">В</span>ремя готовности  <span className='text_num'>8:00</span>. <span className="big_letter">В</span>ремя старта  <span className='text_num'>8:15 - 8:30</span>. 
                        <span className="big_letter">П</span>остроением  колонны , регулировкой движения , обеспечением мер безопасности во время движения, занимаются маршалы из мото-отсечки во главе
                        с <span className="big_letter">Д</span>митрием "Штурмом". <span className='red'> <span className="big_letter">Б</span>ольшая просьба ко всем участникам: соблюдать все правила движения в колонне и  выполнять команды маршалов отсечки ! </span>
                    </p>
                    <p className='text_part' ><span className="big_letter">В</span> голове колонны , в числе первых двигается ретро-техника. За ними в порядке определённом маршалами
                        следуют мотоклубы и другие обьединения. Малоопытные мотоциклисты - замыкают колонну. Участники, прибывшие на автомобилях , двигаются за колонной .
                    </p>
                    <p className='text_part' > <span className="big_letter">Д</span>линна маршрута <span className='text_num'>40</span>км. <span className="big_letter">У</span>частники совершают остановку у мемориала "разорваное кольцо", где проходит митинг 
                        и возложение венков . <span className="big_letter">М</span>отопробег по Дороге Жизни- мероприятие торжественное и пямятное, которое проходит с посещением мемориалов воинской славы, поэтому позаботьтесь, пожалуйста, о цветах для возложения. 
                        <span className="big_letter">М</span>еста парковки техники определяют маршалы из мото-отсечки. <span className="big_letter">Д</span>алее колонна двигается к жд  станции <span className="big_letter">Л</span>адожское озеро где  разворачивается на круговом движении 
                        и  останавливается напротив музея "<span className="big_letter">Д</span>орога Жизни".
                    </p>
                    <p className='text_part' ><span className="big_letter">В</span> составе колонны мотоциклисты двигаются попарно, соблюдая интервал и дистанцию.<span className="big_letter">В</span>о избежание <span className="big_letter">ДТП</span>-<span className="red">выезжать  из состава колонны запрещено ! </span>
                        <span className="big_letter">И</span>сключениями  являются <span className="big_letter">ДТП</span> и поломка <span className="big_letter">ТС</span>.  <span className="big_letter">Ф</span>отографы и видеооператоры должны заранее заявить о своём участии и виде транспорта, на котором они будут.
                        При построеннии колонны необходимо получить инструкции у организаторов . 
                    </p>
                </div>
                <YMaps>
                <div className="column_map">
                    <Map defaultState={{ center: [60.016036, 30.591427], zoom: 17 }}
                        width='100%' height='100%' >
                        <Placemark geometry={[60.016036, 30.591427]} />
                    </Map>
                </div>
                </YMaps>
            </div>
        </div>
    )
}
export default Column;

