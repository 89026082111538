import {Routes,Route, useLocation} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import MainPage from './pages/MainPage';
import  Biennale from './pages/Biennale';
import Payment from './pages/Payment';
import RoadTwoZero from './pages/RoadTwoZero';
import Page404 from './pages/404';




const AnimatedRoutes = function(){
    const location = useLocation();
    return(
        <AnimatePresence >
            <Routes location = {location} key={location.pathname}>
                <Route path='/'  element ={<MainPage/>}/>
                <Route path='/biennale' element ={<Biennale/>}/>
                <Route path='/payment' element = {<Payment/>}/>
                <Route path='/twozero' element = {<RoadTwoZero/>}/>
                <Route path='*' element={<Page404/>}/>
            </Routes>
        </AnimatePresence>
    )
}
export default AnimatedRoutes;