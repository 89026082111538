import React from 'react';
import './footer.css';

const Footer = ()=>{
    return(
        <div className="footer">
            <div className="footer_wrapper">
                <div className="footer_description">
                    <img src='/images/logo_mini.png' alt='logo' className='footer_img'/>
                    © Мотопробег Дорога жизни 2024
                </div>
            </div>
        </div>
    )
}
export default Footer;