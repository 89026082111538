import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import './gallery.css';


const Gallery = function(){
    return(
        <motion.div className="gallery" id='gallery'
            initial={{opacity:0}}
            animate={{opacity:1}}
            exit={{opacity:0}}
        >
        <h3 className="about_title">Об участниках</h3>
            <div className="gallery_wrapper">
                <div className="gallery_description">
                    <div className="gallery_text"> 
                        <p className="text_part">
                            <span className="big_letter">О</span>громную помощь в подготовке , организации и проведении мотопробега  оказываете вы.  <span className="big_letter">М</span>отоциклисты, автомобилисты и просто неравнодушные. <span className="big_letter">Б</span>лагодаря нашим совместным усилиям, каждый год мы собираемся вместе. <span className="big_letter">Р</span>ебята помогают не только словом, но и делом.  
                            <span className="big_letter"> Н</span>еоценимую помощь в развитии <span className="big_letter">М</span>отопробега оказывает <span className="big_letter">С</span>ергей <span className="big_letter">И</span>ванович <span className="big_letter">П</span>анин и <span className="big_letter">С</span>овет <span className="big_letter">В</span>етеранов <span className="big_letter">Д</span>ороги <span className="big_letter">Ж</span>изни. <span className="big_letter">А</span>ктивно участвуют в помощи и организации  мотоклубы и мотообъединения.
                        </p>
                        <p className="text_part">
                            <span className="big_letter">В</span> какой-то момент решили организовать полевую кухню.  <span className="big_letter">И</span>горь «Пожарюга»-первый инициативный человек, который предложил свои услуги. <span className="big_letter">О</span>н - сотрудник <span className="big_letter">МЧС</span> предоставил полевую кухню.
                            <span className="big_letter"> С</span>оздалась инициативная группа, преимущественно из мотодоноров. <span className="big_letter">М</span>аша «Тётя», <span className="big_letter">И</span>ван <span className="big_letter">К</span>узьмин, «Злюка» <span className="big_letter">Н</span>адя. <span className="big_letter">О</span>ни кашеварили, раздавали и так далее. <span className="big_letter">Л</span>юдей становится больше, а кормить их всё тяжелее.  <span className="big_letter">В</span> какой-то год   поставили  три полевые кухни.  
                        </p>
                        <p className="text_part">
                            <span className="big_letter">П</span>ри содействии мотоклуба "Штрафбат", в <span className="big_letter">М</span>отопробеге появился "Фома" - организовавший музыкальную программу мероприятия. <span className="big_letter">В</span> юбилейный год хорошо украсила мероприятие <span className="big_letter">Л</span>ена «Даник», саксофонистка. <span className="big_letter">О</span>на с девочками на подтанцовке  красиво выступила с оригинальной программой.  
                        </p>
                        <p className="text_part">
                            <span className="big_letter">П</span>аша «Мотовоз». <span className="big_letter">С</span>ветлая ему память. <span className="big_letter">А</span>ктивно занимался организацией логистики, второй сценой.  <span className="big_letter">О</span>собенно хочется отметить работу наших "маршалов" из отсечки и их безсменного лидера <span className="big_letter">Д</span>митрия "Штурма". <span className="big_letter">Б</span>лагодаря общим усилиям, <span className="big_letter">М</span>отопробег из года в год , двигается в правильном направлении.             
                        </p>
                    </div>
                </div>
                <Link to='/biennale' className='gallery_link'><button className='gallery_btn'>галерея</button></Link>
            </div>
        </motion.div>
    )
}
export default Gallery;