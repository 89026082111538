import {Link} from 'react-router-dom';
import { motion } from "framer-motion";
import './navigation.css';

const Navigation = (props)=>{
    const{isActive} = props;
    const navVariants = {
        hidden:{
                opacity: 0},
        visible:{
                opacity: 1}
    };
    return(
        <nav className={`navigation   ${isActive ? "navigation_active " : ""} `}>
            <motion.ul className="navigation__list" variants={navVariants} initial={'hidden'} animate={'visible'} transition={{delay:0.7, duration:1}}>
                <motion.li whileHover={{scale:1.3}}  className="navigation__item"><a href="#about" className="navigation__link">о проекте</a></motion.li>
                <motion.li whileHover={{scale:1.3}}  className="navigation__item"><Link  to ="/biennale" className="navigation__link">галерея</Link></motion.li>
                <motion.li whileHover={{scale:1.3}}  className="navigation__item"><a href="#helpus" className="navigation__link">помощь проекту</a></motion.li>
                <motion.li whileHover={{scale:1.3}} className="navigation__item"><Link  to ="/twozero" className="navigation__link">дорога жизни 2.0</Link></motion.li>
                <motion.li whileHover={{scale:1.3}}  className="navigation__item"><a href="#contacts" className="navigation__link">контакты</a></motion.li>
            </motion.ul>
        </nav>
    )
}
export default Navigation;