import { Link } from "react-router-dom";
import './404.css';
const Page404 = ()=>{
    return(
        <div className="not_page">
            <div className="page_wrapper">
                <h2 className="not_page-title">404</h2>
                <Link to="/" className="link_home">На главную</Link>
            </div>
        </div>
    )
}
export default Page404;