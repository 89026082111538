import React from 'react';
import {Link} from 'react-router-dom';
import './helpUs.css';


const HelpUs = ()=>{
    return(
        <div className="helpUs" id='helpus'>
            <h3 className='helpUs_title'>Помощь проекту</h3>
                <div className="helpUs_wrapper">
                    <div className='helpUs_logo'>
                        <Link to = '/' className="helpUs_logo-link">
                            <img src='/images/logo.png' alt='logo' className='helpUs_logo-img'/>
                        </Link>
                    </div>
                    <div className="helpUs_text">
                        <div  className='helpUs_description'>
                                <p className='text_part'><span className="big_letter">М</span>отопробег "<span className="big_letter">Д</span>орога жизни" - не является коммерческим или госсударственным  проектом . <span className="big_letter">Ф</span>инансирование пробега  осуществляется из личных средств организаторов .</p>
                                <p className='text_part' ><span className="big_letter">Е</span>жегодно количество участников увеличивается. <span className="big_letter">В</span>сё больше и больше мотоциклистов , хотят отдать дань уважения  предкам , отдавшим жизни  за спасение города на Неве.</p>
                                <p className='text_part' ><span className="big_letter">Л</span>юбой из нас, может оказать сущёственную помощь в развитии нашего общего дела ,
                                приобретая атрибутику пробега или сделав безвозмездный перевод на счёт , перейдя на страницу помощи.
                                </p>
                        </div>
                </div>
                </div> 
                <Link to = '/payment' className="helpUs_link"><button className='helpUs_btn'>помочь</button></Link>
        </div>
    )
}
export default HelpUs;