import React from 'react';
import {motion} from 'framer-motion';
import { textAnimation } from '../FunctionMotion';
import { Link } from 'react-router-dom';
import './contacts.css';

const Contacts = ()=>{
    return(
        <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{amount:0.3, once: true}}
        className="contacts" id='contacts'>
            <motion.h3  variants={textAnimation} custom={1} className="contacts_title">Контакты</motion.h3>
            <motion.div className="contacts_wrapper">
                <Link className='social' to='https://t.me/dorogazhiznispb'>
                <motion.img variants={textAnimation} custom={2} className='social_img' src="/images/telegram.svg" alt="Telegram" />
                </Link>
                <Link className='social' to='https://vk.com/motoprobeg1945'>
                <motion.img variants={textAnimation} custom={3} className='social_img' src="/images/vk.svg" alt="VKontakte" />
                </Link>
            </motion.div>
            <div className="contacts_description">
                
            </div>
        </motion.div>
    )
}
export default Contacts;