import React from 'react';
import './aboutproject.css';

const AboutProject = function(){
    return(
        <div className="aboutproject" id = 'about'>
                <h3 className="about_title">О проекте</h3>
                <div className="about_info-top">
                    <div  className="about_text">
                    <p className="text_part">
                            <span className='big_letter'>О</span>т организатора:
                            </p>
                        <p className='text_part'>
                            "<span className='big_letter'>П</span>ервый мотопробег по дороге жизни  стартовал в <span className='text_num'>2009</span> году. 
                            <span className='big_letter'>З</span>ародилась   эта история совершенно случайно. <span className='big_letter'>Н</span>а тот момент на проспекте <span className='big_letter'>К</span>осыгина существовало <span className='big_letter'>М</span>отодепо «<span className='big_letter'>А</span>дреналин»  под руководством <span className='big_letter'>П</span>аши «<span className='big_letter'>А</span>дреналина».
                            <span className='big_letter'>М</span>ы там часто собирались и, в какой-то момент, ближе ко <span className='big_letter'>Д</span>ню <span className='big_letter'>П</span>обеды, он предложил съездить в <span className='big_letter'>М</span>узей <span className='big_letter'>Д</span>ороги <span className='big_letter'>Ж</span>изни и на <span className='big_letter'>Р</span>азорванное <span className='big_letter'>К</span>ольцо.   
                        </p>
                        <p className='text_part' >
                            <span className='big_letter'>Н</span>ас тогда было   человек пять-шесть. <span className='big_letter'>И</span>нформация о предстоящей поездке разнеслась в мотокругах.
                            <span className='big_letter'>В</span> результате, совершенно неожиданно, собралось около <span className='text_num'>15</span> мотоциклов. <span className='big_letter'>Э</span>то   первый состав, и он   на вот этих фотографиях.
                            <span className='big_letter'>С</span> нами уже нет <span className='big_letter'>Л</span>ёхи <span className='big_letter'>А</span>ндреева "<span className='big_letter'>К</span>аратиста", 
                            <span className='big_letter'>В</span>алеры <span className='big_letter'>Ш</span>ирякова "<span className='big_letter'>Ш</span>курника", но все остальные ребята продолжают приезжать на <span className='big_letter'>Д</span>орогу <span className='big_letter'>Ж</span>изни в <span className='big_letter'>Д</span>ень <span className='big_letter'>П</span>обеды.
                        </p>
                    </div>
                    <img src="/images/about.jpg" alt="img" className="about_img" />
                </div>
                <div className="about_future">
                    <img src="/images/about2.jpg" alt="img" className="about_img" />
                        <div className="about_text" >
                            <p className="text_part">
                            <span className='big_letter'>М</span>ои родители - дети блокадного <span className='big_letter'>Л</span>енинграда. <span className='big_letter'>М</span>ой дед, дядя моей мамы, с первого до последнего дня войны всю <span className='big_letter'>Д</span>орогу <span className='big_letter'>Ж</span>изни проехал за рулем «полуторки» и, благодаря ему, живы остались и мои родители, и другие дети города.
                                <span className='big_letter'>В</span> память о моем деде я решил принять <span className='big_letter'>П</span>ашино предложение доехать до этого музея. <span className='big_letter'>Я</span> был крайне удивлен, что до этого момента   никогда там не был, как и большинство жителей <span className='big_letter'>П</span>етербурга.
                                <span className='big_letter'>Н</span>а <span className='big_letter'>Р</span>азорванном кольце я был в пионерском возрасте. <span className='big_letter'>А</span> вот дальше - точно не ездил. <span className='big_letter'>К</span>огда я туда приехал, удивился   тому, что там увидел. <span className='big_letter'>В</span>о-первых, это очень старенькая была избушка.
                                <span className='big_letter'>С</span> небольшим наполнением того, что достали из-подо льда <span className='big_letter'>Л</span>адоги. <span className='big_letter'>К</span>акие-то макеты стояли- самолет, катер. <span className='big_letter'>Э</span>то было всё в   грустном состоянии.
                            </p>
                            <p className='text_part'>
                                <span className='big_letter'>В</span> первый раз колонна   уперлась в то, что мы поехали через <span className='big_letter'>В</span>севоложск, а во <span className='big_letter'>В</span>севоложске уже в это время проходили праздничные мероприятия. <span className='big_letter'>Д</span>орога была перекрыта. 
                                <span className='big_letter'>В</span>  первый же пробег мы столкнулись с тем, что на всех <span className='text_num'>46</span> километрах <span className='big_letter'>Д</span>ороги <span className='big_letter'>Ж</span>изни до <span className='big_letter'>Л</span>адоги не было ни единой заправки. <span className='big_letter'>П</span>ара мотоциклов обсохли. 
                            </p>
                            <p className="text_part">
                            <span className='big_letter'>В</span> городе удобно было собираться на   заправке «НЕСТЕ» , но количество мотоциклов росло и  стало тяжело  выводить колонну из города.
                                <span className='big_letter'>О</span>сновываясь  на опыте, который получали, мы выявили, что удобное место сбора на <span className='big_letter'>АЗС</span> «<span className='big_letter'>Г</span>азпромнефть». <span className='big_letter'>Э</span>то шестой километр <span className='big_letter'>Д</span>ороги <span className='big_letter'>Ж</span>изни после старого поста <span className='big_letter'>ГИБДД</span>." 
                            </p>
                        </div>
                </div>
        </div>
    )
}
export default AboutProject;