import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './roadTwoZero.css';

const RoadTwoZero = ()=>{
    return(
        <div className="roadtwozero">
            <Helmet>
                <meta
                    name="description"
                    content="motorcycle road"
                />
                <title>Дорога жизни 2.0</title>
            </Helmet>
            <h2 className="roadtwozero_title">Дорога жизни 2.0</h2>
            <div className="roadtwozero_wrapper">
                <p className="roadtwozero_description">
                    Инициативная группа мотоциклистов проводит сбор средств и доставку гуманитарной помощи 
                    в район проведения  Специальной Военной Операции.
                </p>
            </div>
            <div className="roadtwozero_links">
                <Link to='https://vk.com/motoprobeg1945?source=description&w=donut_payment-120409282' className='roadtwozero_link'><button className='tomain_btn'>Помочь проекту</button></Link> 
                <Link to='/' className='roadtwozero_link'><button className='tomain_btn'>на главную</button></Link> 
            </div>
        </div>
    )
}
export default RoadTwoZero;