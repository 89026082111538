import '../headerBurger/headerBurger.css';

const HeaderBurger = function(props){
    const{handleToggle,isActive} = props;
    return(
        <div className="header_burger-content">
            <button  onClick = {handleToggle} className={`burger header_burger ${isActive ? "burger_active":"" } `}><span className="burger__line"></span></button>
        </div>
    )
}
export default HeaderBurger;