import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './payment.css';

const dataCards = [
    {
        title:'Наклейка',
        img:'/images/main_stick1.png',
        description:'Оригинальная наклейка мотопробега'
    },
    {
        title:'Нашивка',
        img:'/images/stick_two.png',
        description:'Оригинальная нашивка мотопробега'
    },
    {
        title:'Значок',
        img:'/images/gift.png',
        description:'Оригинальный значок мотопробега'
    },
    {
        title:'Толстовка',
        img:'/images/hoody.png',
        description:'Оригинальная кофта мотопробега с изображением'
    }
    ];

    const paymentTitle = 'Помощь проекту';
    const paymentDescription = 'Помогая проекту, вы вносите свой вклад в наше общее дело . Средства потраченные вами на приобретение товаров с символикой проекта идут не толькео на подарки ветеранам  но и на развитие проекта. Помогая проекту  материально вы можете получить в подарок :';

const Payment = ()=>{
    const [{title,img,description}] = dataCards;
    return(
        <div className="payment">
            <Helmet>
                <meta
                    name="description"
                    content="payment"
                />
                <title>Помощь проекту</title>
            </Helmet>
            <h1 className="payment_title">{paymentTitle}</h1>
            <h2 className="payment_description">{paymentDescription}</h2>
            <div className="card-wrapper">
                {dataCards.map((item,index)=>{
                    return(
                        <div className="card" key={index}>
                            <h2 className="card_title">{item.title}</h2>
                            <img className='card_img' src={item.img} alt={item.title}/>
                            <p className="description">{item.description}</p>
                        </div>
                    )
                })}
            </div>
            <div className="button_wrapper">
                <Link to='https://vk.com/motoprobeg1945?source=description&w=donut_payment-120409282' className='donate_link'><button className='tomain_btn'>Помочь проекту</button></Link> 
                <Link to='/' className='tomain_link'><button className='tomain_btn'>на главную</button></Link> 
            </div>
        </div>
    )
}
export default Payment;